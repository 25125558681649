import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {
  supportEmail = 'atm@travelmoneygroup.com';
  
  constructor(private router: Router) {}

  navigateToHome()
  {
    this.router.navigateByUrl('home');
  }

}
