export const environment = {
    production: false,
    apiUrl: 'https://web.api.dev.takenote.cloud',
    originatorId: 'travel-money-oz-dev-originator',
    firebaseConfig: {
        apiKey: "AIzaSyAaHsKEDqmexvxDHkYIsRT-h2XnQxAWbF8",
        authDomain: "cc-tmoz-test.firebaseapp.com",
        projectId: "cc-tmoz-test",
        storageBucket: "cc-tmoz-test.appspot.com",
        messagingSenderId: "1041662873663",
        appId: "1:1041662873663:web:506a690cf698a611e8288a",
        measurementId: "G-ZZ4ZRQLP6G"
      }
};