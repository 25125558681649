<div class="container">
    <div class="content">
        <div class="title-container">
            <img src="../../../assets/images/TMOZ Logo - Centered - Reversed - NoHammer-NoTagline.png" alt="Travel Money OZ" />
            <!-- <div class="title-text-container">
                <span class="title-text">Flight Centre</span>
            </div> -->
        </div>
        <!-- <img class="img-style" src="../../../assets/pics/tmoz-logo.png" alt="Travel Money OZ" /> -->
        <div class="top-banner">
            <!-- <img src="../../../assets/DD-9257_Deals Page Header_desktop_1920x550.jpg" alt="Martin Garrix" /> -->
            <img src="../../../assets/images/ATM Web Banner.png" alt="Travel Money OX" />
            <!-- <img src="../../../assets/pics/MIC_TMG_CBA_Currency-Pass-RTW-blog_1920x420_v1.jpg" alt="Travel Money OX" /> -->
            <!-- <div class="top-banner-curved-edge"></div> -->
        </div>
        <div class="top-banner-curved-edge-backgroud">
            <div class="top-banner-curved-edge"></div>
        </div>
        <div class="form-content">

            @if(!this.addedToCart){
                <div class="bc-panel-body-grid">
                    <div class="bc-layout-grid">

                        <div class="section-header">
                            <span class="section-header-text">Select Currency and Amount to collect</span>
                            <!-- <div> -->
                                <!-- <mat-icon>currency_exchange</mat-icon> -->
                                <fa-icon [icon]="['fas', 'rotate']" size="xl"></fa-icon>
                            <!-- </div> -->
                        </div>

                        <div class="currency-field-layout">
                            <div class="currency-field-input">
                                <mat-form-field>
                                    <mat-label>Currency</mat-label>
                                    <span matPrefix><span [class]="flagStyle"></span></span>
                                    <mat-select [(value)]="selectedCurrencyAmount" (openedChange)="onCurrencyListOpened($event)"  (selectionChange)="onCurrencySelected()" >
                                        @for (c of currencyAmounts; track $index) {
                                            <mat-option [value]="c"><div class="flag-style"><span [ngClass]="'fi fi-' + c.country.toLocaleLowerCase()"></span> {{c.currency}}</div></mat-option>
                                        }
                                    </mat-select>
                                    <mat-hint>What you get</mat-hint>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>Amount</mat-label>
                                    <!-- <mat-select [(value)]="selectedCurrencyOption.selectedAmount" (selectionChange)="updateBaseAmount()" [disabled]="this.addedToCart">
                                        @for (o of selectedCurrencyOption.amountsAvailable; track $index) {
                                            <mat-option [value]="o">{{o}}</mat-option>
                                        }
                                    </mat-select> -->
                                    <input matInput type="number" [formControl]="quoteCurrencyAmountControl" (keyup) ="onAmountEntered($event)" placeholder="0.00" maxLength="6" [errorStateMatcher]="errorStateMatcher">
                                    <mat-hint align="end">Min {{selectedCurrencyAmount.minimumAmount}} {{selectedCurrencyAmount.currency}}</mat-hint>
                                    @if (quoteCurrencyAmountControl.invalid) {
                                        <mat-error>Minimum amount is {{selectedCurrencyAmount.minimumAmount}} {{selectedCurrencyAmount.currency}}</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="coversion-content">
                            <div class="conversion-icon">
                                <!-- <mat-icon>double_arrow</mat-icon> -->
                                <!-- <fa-icon [icon]="faAnglesDown"></fa-icon> -->
                                <fa-icon [icon]="['fas', 'angles-down']" size="xl"></fa-icon>
                            </div>

                            <div class="rates-display">
                                @if(this.rateLoading){
                                    <div class="spinner-container">
                                        <mat-spinner [diameter]="20"></mat-spinner>
                                    </div>
                                }
                                @else{
                                    <h3 class="rates-text">1 {{baseCurrency}} = {{rate | number: '1.2-4'}} {{selectedCurrencyAmount.currency}}</h3>
                                }
                            </div>

                            <div class="conversion-icon">
                                <!-- <mat-icon>double_arrow</mat-icon> -->
                                <!-- <fa-icon [icon]="faAnglesDown"> </fa-icon> -->
                                <fa-icon [icon]="['fas', 'angles-down']" size="xl"></fa-icon>
                            </div>
                        </div>

                        <div class="currency-field-input">
                            <mat-form-field appearance="outline">
                                <mat-label>Currency</mat-label>
                                <input matInput class="input-style" type="text" value="{{baseCurrency}}" readonly />
                                <span matPrefix><span class="flag-style fi fi-au"></span></span>
                                <mat-hint>What you pay</mat-hint>
                            </mat-form-field>
                                @if(this.rateLoading){
                                    <mat-form-field appearance="outline">
                                        <mat-label>Amount</mat-label>
                                        <input matInput class="input-style" type="text" min="1" value=" " readonly />
                                        <mat-spinner matPrefix [diameter]="20" style="display: inline-flex; padding-right: 30px"></mat-spinner>
                                    </mat-form-field>
                                }
                                @else {
                                    <mat-form-field appearance="outline">
                                        <mat-label>Amount</mat-label>
                                        <!-- <input matInput class="input-style" type="text" min="1" value="{{baseCurrencyAmount | number: '1.2-2'}}" readonly /> -->
                                        <input matInput class="input-style" type="text" [formControl]="baseCurrencyAmountControl" readonly/>
                                        <mat-hint align="end">Max 1000 {{baseCurrency}}</mat-hint>
                                        @if (this.baseCurrencyAmountControl.invalid) {
                                            <mat-error>Max of 1000 AUD exceeded</mat-error>
                                        }
                                    </mat-form-field>
                                }
                        </div>
                        
                        <div class="cart-container">
                            <button class="cart-btn" mat-raised-button color="custom-color" [class.spinner]="payLoading" (click)="this.onAddToCart()" 
                            [disabled]="this.addedToCart || this.quoteCurrencyAmountControl.invalid || this.baseCurrencyAmountControl.invalid" >
                                Add to cart
                            </button>
                        </div>

                    </div>
                </div>
            }

            <!-- ====================================================================================================================== -->
            @if(this.addedToCart){
                <div class="bc-panel-body-grid">
                    <div class="cart-layout-grid">
                        
                        <div class="section-header">
                            <span class="section-header-text">Your Cart</span>
                            <div>
                                <!-- <mat-icon>account_box</mat-icon> -->
                                <fa-icon matSuffix [icon]="['fas', 'cart-shopping']" size="xl"></fa-icon>
                            </div>
                        </div>

                        <div class="cart">
                            <div class="cart-line-item">
                                <div class="cart-item">
                                    <div  class="cart-currency-item">
                                        <span class="cart-flag-style fi fi-au"></span>
                                        <p class="cart-base-currency-amount">{{this.baseCurrencyAmountControl.value | number: '1.2-2'}}</p>
                                        <p class="cart-base-currency">{{baseCurrency}}</p>
                                    </div>

                                    <!-- <div class="cart-rate-item"> -->
                                        <p class="cart-rate">1 {{baseCurrency}} = {{rate}} {{selectedCurrencyAmount.currency}}</p>
                                    <!-- </div> -->

                                    <div  class="cart-currency-item">
                                        <span [ngClass]="'cart-flag-style fi fi-' + selectedCurrencyAmount.country.toLowerCase()"></span>
                                        <p class="cart-base-currency-amount">{{quoteCurrencyAmountControl.value | number: '1.2-2'}}</p>
                                        <p class="cart-base-currency">{{selectedCurrencyAmount.currency}}</p>
                                    </div>
                                </div>

                                <div class="cart-item-delete-btn-container">
                                    <button mat-icon-button (click)="this.onRemoveFromCart()">
                                        <fa-icon class="cart-item-delete-icon" [icon]="['fas', 'trash-can']" [style]="{padding: '0px 0px 0px 0px'}" size="2xs" ></fa-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="total-line">
                                <p class="total-text">Total amount to pay: {{baseCurrencyAmountControl.value | number: '1.2-2'}} {{baseCurrency}}</p>
                            </div>
                            <p class="total-hint">To edit your order, remove the existing cart item</p>
                        </div>

                        @if (this.amountRoundDownWarning) {
                            <div class="note-container">
                                <fa-icon [icon]="['fas', 'triangle-exclamation']" [style]="{padding: '0px 0px 0px 0px'}" size="2xl" ></fa-icon>
                                <p class="note-text">You currency amount has been rounded down to the denomination that can be withdrawn from the ATM</p>
                            </div>
                        }

                        @if (this.amountRoundUpWarning) {
                            <div class="note-container">
                                <fa-icon [icon]="['fas', 'triangle-exclamation']" [style]="{padding: '0px 0px 0px 0px'}" size="2xl" ></fa-icon>
                                <p class="note-text">You currency amount has been rounded up to the denomination that can be withdrawn from the ATM</p>
                            </div>
                        }

                    </div>
                </div>

            <!-- ====================================================================================================================== -->
            <div class="bc-panel-body-grid">
                <div class="bc-layout-grid">
                    <div class="section-header">
                        <span class="section-header-text">Enter your personal details</span>
                        <div>
                            <!-- <mat-icon>account_box</mat-icon> -->
                            <fa-icon matSuffix [icon]="['fas', 'address-card']" size="xl"></fa-icon>
                        </div>
                    </div>
                    <div>
                        <span>Enter your first name</span>
                        <mat-form-field>
                            <!-- <mat-label>Email address</mat-label> -->
                            <input matInput type="text" #firstname [formControl]="firstnameControl" placeholder="John" maxLength="80">
                            <!-- <mat-icon matSuffix>person</mat-icon> -->
                            <fa-icon matSuffix [icon]="['fas', 'user']" class="field-icon" [style]="{padding: '0px 20px 0px 0px'}" [fixedWidth]="true" size="lg"></fa-icon>
                            <mat-hint align="end">{{firstname.value.length}} / 80</mat-hint>
                            @if (firstnameControl.invalid) {
                                <mat-error>You must enter a valid first name</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div>
                        <span>Enter your last name</span>
                        <mat-form-field>
                            <!-- <mat-label>Email address</mat-label> -->
                            <input matInput type="text" #lastname [formControl]="lastnameControl" placeholder="Smith" maxLength="80">
                            <!-- <mat-icon matSuffix>person_add</mat-icon> -->
                            <fa-icon matSuffix [icon]="['fas', 'user-plus']" class="field-icon" [style]="{padding: '0px 20px 0px 0px'}" [fixedWidth]="true" size="lg"></fa-icon>
                            <mat-hint align="end">{{lastname.value.length}} / 80</mat-hint>
                            @if (lastnameControl.invalid) {
                                <mat-error>You must enter a valid last name</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div>
                        <span>Enter your email address</span>
                        <mat-form-field>
                            <!-- <mat-label>Email address</mat-label> -->
                            <input matInput type="email" [formControl]="emailControl" placeholder="john.smith@example.com">
                            <!-- <mat-icon matSuffix>email</mat-icon> -->
                            <fa-icon matSuffix [icon]="['fas', 'envelope']" class="field-icon" [style]="{padding: '0px 20px 0px 0px'}" [fixedWidth]="true" size="lg"></fa-icon>
                            @if (emailControl.invalid) {
                                <mat-error>You must enter a valid email address</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div>
                        <span>Enter your phone number</span>
                        <mat-form-field>
                            <mat-label>Phone number</mat-label>
                            <span matTextPrefix>+61 &nbsp;</span>
                            <input matInput type="tel" [formControl]="phoneControl" placeholder="12345678" maxLength="9">
                            <!-- <mat-icon matSuffix>call</mat-icon> -->
                            <fa-icon matSuffix [icon]="['fas', 'phone']" class="field-icon" [style]="{padding: '0px 20px 0px 0px'}" [fixedWidth]="true" size="lg"></fa-icon>
                            @if (phoneControl.invalid) {
                                <mat-error>You must enter a valid phone number</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div>
                        <span>Enter your date of birth (DD/MM/YYYY)</span>
                        <mat-form-field class="dob-field-input">
                            <div class="dob-field">
                                <input matInput [formControl]="dobControl" [matDatepicker]="picker" [min]="minDate" [max]="maxDate" placeholder="DD/MM/YYYY" readonly="true">
                                <button class="dob-field-button" mat-raised-button color="custom-color" (click)="picker.open()">
                                    <fa-icon [icon]="['fas', 'calendar-days']" [style]="{padding: '0px 20px 0px 0px'}"  size="lg"></fa-icon>
                                    <span>Calendar</span>
                                    <!-- <mat-icon class="dob-field-button-icon">calendar_month</mat-icon> -->
                                    <!-- <fa-icon [icon]="['fas', 'calendar-days']"></fa-icon> -->
                                </button>
                            </div>
                            <mat-datepicker touchUi #picker startView="multi-year" [startAt]="startDate"></mat-datepicker>
                            @if (dobControl.invalid) {
                                <mat-error>You must enter a valid date of birth</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-checkbox class="example-margin" [formControl]="tcControl">
                            I have read and agreed to the <a href="https://www.travelmoneyoz.com/terms-and-conditions" target="_blank">Terms and Conditions</a> 
                        </mat-checkbox>
                    </div>

                    
                    <div class="pay-container">
                        <button class="pay-btn" mat-raised-button color="custom-color" [class.spinner]="payLoading"
                        [disabled]="this.firstnameControl.invalid || this.lastnameControl.invalid || this.emailControl.invalid || this.phoneControl.invalid || this.dobControl.invalid || tcControl.invalid"
                            (click)="payOrder()">
                            PAY
                        </button>
                    </div>

                </div>
            </div>

            <!-- <div class="bc-panel-body-grid">
                <div class="bc-layout-grid">
                    <div class="pay-container">
                        <button class="pay-btn" mat-raised-button color="custom-color" [class.spinner]="payLoading"
                        [disabled]="this.firstnameControl.invalid || this.lastnameControl.invalid || this.emailControl.invalid || this.phoneControl.invalid || this.dobControl.invalid || tcControl.invalid"
                            (click)="payOrder()">
                            PAY
                        </button>
                    </div>
                </div>
            </div> -->

        }

        </div>
    </div>
</div>